import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const InputWrapper = styled.input`
  border: 1px solid
    ${props =>
      props.notValidValue ? props.theme.color.primary : props.theme.color.silverChalice};
  border-radius: 13px;
  color: ${props => props.theme.color.textColor};
  height: 45px;
  padding-left: ${props => props.paddingLeft}px;
  width: 100%;
  outline: none;
  &:focus {
    outline: none;
  }
`

const Label = styled.label`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.color.infludataBlack};
  text-transform: uppercase;
  margin-bottom: 5px;
  align-self: baseline;
  margin-left: 10px;
  font-weight: 500;
`

const InputMainWrapper = styled.div`
  ${props => props.theme.flex.centerAllColumn}
  margin-top: 30px;
  margin-bottom: 20px;
  position: relative;
`

const TextareaWrapper = styled.textarea`
  border: 1px solid
    ${props =>
      props.notValidValue ? props.theme.color.primary : props.theme.color.silverChalice};
  border-radius: 13px;
  color: ${props => props.theme.color.textColor};
  width: 100%;
  height: 170px;
  resize: none;
  padding: 15px;
  &:focus {
    outline: none;
  }
`

const NotValidLabelWrapper = styled.span`
  position: absolute;
  bottom: -20px;
  left: 10px;
  color: ${props => props.theme.color.primary};
  font-size: 13px;
`

const Input = ({
  onChange,
  value,
  notValidValue,
  labelText,
  isMultiline,
  onFocus,
  noValidLabel,
  placeholder,
  paddingLeft,
}) => {
  const inputProps = {
    onChange,
    onFocus,
    value,
    notValidValue,
    noValidLabel,
    ...(labelText && { id: labelText }),
    autoComplete: 'off',
    placeholder,
    paddingLeft,
  }

  return (
    <InputMainWrapper>
      {labelText && <Label htmlFor={labelText}>{labelText}</Label>}
      {isMultiline ? (
        <TextareaWrapper {...inputProps} />
      ) : (
        <InputWrapper {...inputProps} />
      )}

      {notValidValue && <NotValidLabelWrapper>{noValidLabel}</NotValidLabelWrapper>}
    </InputMainWrapper>
  )
}

Input.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  notValidValue: PropTypes.bool,
  isMultiline: PropTypes.bool,
  noValidLabel: PropTypes.string,
  placeholder: PropTypes.string,
  paddingLeft: PropTypes.number,
  labelText: PropTypes.string,
}

Input.defaultProps = {
  onChange: () => {},
  onFocus: () => {},
  value: '',
  notValidValue: false,
  isMultiline: false,
  noValidLabel: '',
  placeholder: '',
  paddingLeft: 15,
  labelText: '',
}

export default Input

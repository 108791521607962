import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { RoundedInput } from '@common/styledWrappers'
import { SOCIAL_PLATFORMS_INDEXES } from '@constants/socialPlatforms'
import { InstagramIcon, TiktokIcon, SearchIcon } from '@common/icons'
import { generalTheme } from '@themes/styledTheme'

const Input = styled(RoundedInput)`
  color: ${props => props.theme.color.textColor};
  height: 46px;
  width: 100%;
  border-radius: 13px;
  font-size: 15px;
  background: rgba(255, 255, 255, 1);
  padding-left: ${props => props.paddingLeft}px;
  &:focus {
    // box-shadow: 0px 0px 15px ${props => props.theme.color.grey};
    // box-shadow: 0px 0px 30px #FF896833;
  }
  ${props => props.isVisibleSuggestList && `z-index: 200;`}
  // 
`

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  ${props => props.isVisibleSuggestList && `z-index: 200;`}
`

const InputIconWrapper = styled.div`
  position: absolute;
  right: 7%;
  top: -36%;
  ${props => props.theme.media.lessThan('mobile')`
    right: 10%;
  `}
  ${props => props.isVisibleSuggestList && `z-index: 200;`}
`

const SearchIconWrapper = styled(InputIconWrapper)`
  right: 2%;
  top: 18%;
  & svg {
    fill: ${props => props.theme.color.grey};
    height: 30px;
    width: 30px;
    & path {
      fill: ${props => props.theme.color.grey};
    }
  }
  ${props => props.theme.media.lessThan('mobile')`
    right: 3%;
  `}
`

const ClearInputWrapper = styled(InputIconWrapper)`
  height: 0;
  width: 0;
  top: -27%;
  font-size: 20px;
  color: ${props => props.theme.color.grey};
  ::before {
    display: block;
    content: '+';
    cursor: pointer;
    font-size: 35px;
    transform: rotateZ(45deg);
  }
`

const LogoWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 18%;
  left: 6px;
  ${props => props.isVisibleSuggestList && `z-index: 300;`}
`

const IconWrapper = styled.div`
  margin: 0 3px;
  ${props => props.theme.flex.centerAll}
  background: ${props => props.bgColor || generalTheme.color.mercury};
  border-radius: 50%;
  height: 30px;
  width: 30px;

  & > span {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  & svg {
    height: 20px;
    width: 20px;
    fill: ${props =>
      props.bgColor ? props.theme.color.white : generalTheme.color.silverChalice}
  }
`

const SWITCHER_COLORS = {
  insta: generalTheme.color.primary,
  tiktok: generalTheme.color.tiktokColor,
  inactive: generalTheme.color.silverChalice,
}

const AnalyzeInput = ({
  onChange,
  inputValue,
  onInputBlur,
  onInputFocus,
  clearInputHandler,
  placeholder,
  withPlatformsSwitch,
  currentPlatformIndex,
  selectedPlatformHandler,
  isVisibleSuggestList,
  id,
}) => {
  return (
    <InputWrapper isVisibleSuggestList={isVisibleSuggestList}>
      {withPlatformsSwitch && (
        <LogoWrapper isVisibleSuggestList={isVisibleSuggestList}>
          <IconWrapper
            isVisibleSuggestList={isVisibleSuggestList}
            id={SOCIAL_PLATFORMS_INDEXES.instagram}
            {...(SOCIAL_PLATFORMS_INDEXES.instagram === currentPlatformIndex && {
              bgColor: SWITCHER_COLORS.insta,
            })}
            onClick={selectedPlatformHandler(SOCIAL_PLATFORMS_INDEXES.instagram)}
          >
            <InstagramIcon />
          </IconWrapper>
          <IconWrapper
            isVisibleSuggestList={isVisibleSuggestList}
            id={SOCIAL_PLATFORMS_INDEXES.tiktok}
            {...(SOCIAL_PLATFORMS_INDEXES.tiktok === currentPlatformIndex && {
              bgColor: SWITCHER_COLORS.tiktok,
            })}
            onClick={selectedPlatformHandler(SOCIAL_PLATFORMS_INDEXES.tiktok)}
          >
            <TiktokIcon />
          </IconWrapper>
        </LogoWrapper>
      )}
      <Input
        onChange={onChange}
        value={inputValue}
        autoComplete='off'
        onBlur={onInputBlur}
        onFocus={onInputFocus}
        paddingLeft={95}
        placeholder={placeholder}
        id={id}
        isVisibleSuggestList={isVisibleSuggestList}
      />
      {inputValue ? (
        <ClearInputWrapper
          onClick={clearInputHandler}
          isVisibleSuggestList={isVisibleSuggestList}
        />
      ) : (
        <SearchIconWrapper isVisibleSuggestList={isVisibleSuggestList}>
          <SearchIcon />
        </SearchIconWrapper>
      )}
    </InputWrapper>
  )
}

AnalyzeInput.propTypes = {
  onChange: PropTypes.func,
  inputValue: PropTypes.string,
  onInputBlur: PropTypes.func,
  onInputFocus: PropTypes.func,
  clearInputHandler: PropTypes.func,
  placeholder: PropTypes.string,
  withPlatformsSwitch: PropTypes.bool,
  currentPlatformIndex: PropTypes.string,
  selectedPlatformHandler: PropTypes.func,
  id: PropTypes.string.isRequired,
  isVisibleSuggestList: PropTypes.bool,
}

AnalyzeInput.defaultProps = {
  onChange: () => {},
  inputValue: '',
  onInputBlur: () => {},
  onInputFocus: () => {},
  clearInputHandler: () => {},
  placeholder: '',
  withPlatformsSwitch: false,
  currentPlatformIndex: SOCIAL_PLATFORMS_INDEXES.instagram,
  selectedPlatformHandler: () => {},
  isVisibleSuggestList: false,
}

export default AnalyzeInput

import React, { useState, useEffect } from 'react'
import { delayFunc, trackEventWithGA } from '@utils'
import httpService from '@services/httpService'
import useTranslation from '@hooks/useTranslation'
import { SOCIAL_PLATFORMS_INDEXES } from '@constants/socialPlatforms'
import SuggestedUsersList from '@common/SuggestedUsersList'
import AnalyzeInput from '@common/inputs/AnalyzeInput'
import { GA_EVENTS_MAP } from '@constants/gaTracking'
import * as Styled from './styled'

const AnalyzeUsers = () => {
  const defaultState = {
    suggestedUsers: [],
    inputValue: '',
    resultsVisible: true,
    currentPlatformIndex: SOCIAL_PLATFORMS_INDEXES.instagram,
  }
  const INPUT_ID = 'analyze_input'

  const [inputValue, changeInputValue] = useState(defaultState.inputValue)
  const [suggestedUsers, changeSuggestedUsers] = useState(
    defaultState.suggestedUsers,
  )
  const [resultsVisible, toggleVisibleResults] = useState(
    defaultState.resultsVisible,
  )

  const [currentPlatformIndex, setCurrentPlatformIndex] = useState(
    defaultState.currentPlatformIndex,
  )

  const [isUserSelectClick, setIsUserSelectClick] = useState(false)

  const { labelStrings } = useTranslation()

  const fetchSuggestedUsers = async searchString => {
    try {
      changeSuggestedUsers(
        await httpService.fetchSuggestedUsers(
          searchString,
          currentPlatformIndex,
        ),
      )
    } catch (err) {
      changeSuggestedUsers(defaultState.suggestedUsers)
    }
  }

  const onChange = async ({ target: { value } }) =>
    changeInputValue(
      !value && suggestedUsers.length ? defaultState.inputValue : value,
    )

  useEffect(() => {
    if (inputValue.length > 3) {
      delayFunc(async () => await fetchSuggestedUsers(inputValue), 300)
    }
  }, [inputValue, currentPlatformIndex])

  const clearInputHandler = () => changeInputValue(defaultState.inputValue)

  const userSelectClickHandler = () => {
    trackEventWithGA({
      eventCategory: GA_EVENTS_MAP.categories.infludataApp,
      eventAction: GA_EVENTS_MAP.actions.findCreator,
    })
    setIsUserSelectClick(true)
  }

  const onInputFocus = () => {
    if (!resultsVisible) toggleVisibleResults(true)
    if (isUserSelectClick) setIsUserSelectClick(false)
  }

  const onInputBlur = e => {
    const nextElId = e.relatedTarget && e.relatedTarget.id
    if (
      SOCIAL_PLATFORMS_INDEXES[nextElId] &&
      nextElId !== currentPlatformIndex
    ) {
      return
    }
    delayFunc(() => !isUserSelectClick && toggleVisibleResults(false), 50)
  }

  const onChangeSocialPlatform = newPlatformIndex => () => {
    setCurrentPlatformIndex(newPlatformIndex)
    toggleVisibleResults(true)
    document.getElementById(INPUT_ID).focus() // set focus on input
  }

  const notFoundUsers = !!inputValue && !suggestedUsers.length
  const isVisibleSuggestList = !!inputValue && !!resultsVisible

  return (
    <Styled.Wrapper>
      <Styled.Title>{labelStrings.analyseYourInfluencer}</Styled.Title>
      <Styled.AnalyzeInputWrapper unroundInputBottomBorder={isVisibleSuggestList}>
        <AnalyzeInput
          id={INPUT_ID}
          onChange={onChange}
          inputValue={inputValue}
          onInputBlur={onInputBlur}
          onInputFocus={onInputFocus}
          clearInputHandler={clearInputHandler}
          withPlatformsSwitch
          currentPlatformIndex={currentPlatformIndex}
          selectedPlatformHandler={onChangeSocialPlatform}
          placeholder={labelStrings.username}
          isVisibleSuggestList={isVisibleSuggestList}
        />

        <SuggestedUsersList
          isVisible={isVisibleSuggestList}
          suggestedUsersList={suggestedUsers}
          userSelectClickHandler={userSelectClickHandler}
          notFound={notFoundUsers}
          currentPlatformIndex={currentPlatformIndex}
        />
      </Styled.AnalyzeInputWrapper>
    </Styled.Wrapper>
  )
}

export default AnalyzeUsers

import styled from 'styled-components'
import { MaxWidthWrapper } from '@common/styledWrappers'
import { SectionTitle } from '../styled'

export const MainWrapper = styled.div`
  ${props => props.theme.flex.centerAllColumn};
  max-width: 1300px;
  margin: 0 auto;
  padding: 40px 20px;

  ${props => props.theme.media.lessThan('mid')`
    position: relative;
    top: -20px;
  `}

  ${props => props.theme.media.lessThan('1150px')`
    width: 85%;
    margin: 10px auto 0 auto;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    position: relative;
    width: 100%;
    top: -40px;
  `}
`

export const Title = styled(SectionTitle)`
  text-align: center;
  margin-bottom: 30px;
  ${props => props.theme.getTextGradientStyles()};

  ${props => props.theme.media.lessThan('tablet')`
    margin-top: 30px;
    margin-bottom: 15px;
  `}
`

export const FeatureItem = styled.div`
  ${props => props.theme.flex.centerAllColumn};
  width: 240px;
  margin: 20px 15px;

  ${props => props.theme.media.lessThan('1150px')`
    margin: 20px auto;
  `}
`

export const FeatureBubbled = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background: ${props => props.theme.infludataMainGradient};
  box-shadow: 0px 39px 42px -15px rgba(203, 179, 179, 0.44);
  font-size: 40px;
  ${props => props.theme.flex.centerAll};
  font-weight: bold;
`

export const FeatureTitle = styled.div`
  ${props => props.theme.flex.centerAll};
  justify-content: flex-start;
  font-size: 15px;
  line-height: 19px;
  font-weight: bold;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)};
  text-align: center;
  margin-top: 20px;
  max-width: 200px;
`

export const FeaturesNonSliderWrapper = styled(MaxWidthWrapper)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  align-items: baseline;
`

import styled from 'styled-components'

export const CardsWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 20px;

  ${props => props.theme.media.lessThan('mid')`
    display: none;
  `}
`

export const SliderCardsWrapper = styled.div`
  display: none;

  ${props => props.theme.media.lessThan('mid')`
    width: 100%;
    display: block;
  `}
`

export const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
  border-radius: 25px;
  background-color: ${props => props.theme.color.bgLight};

  ${props => props.theme.media.lessThan('mid')`
    width: 90%;
    max-width: 350px;
    margin: 0 auto;
  `}
`

export const CardIconWrapper = styled.div`
  & img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
`

export const CardTitle = styled.div`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)}
`

export const CardText = styled.div`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.color.infludataBlack};
`

export const CardImage = styled.img``

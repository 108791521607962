import React from 'react'
import useTranslation from '@hooks/useTranslation'
import TryForFreeButton from '@common/buttons/TryForFreeButton'
import { SectionWrapper } from '../styled'
import * as Styled from './styled'

const InsideInfludata = () => {
  const { labelStrings } = useTranslation()
  const { insideInfludata: insideInfludataStrings } = labelStrings
  const { items: itemsStrings } = insideInfludataStrings

  return (
    <SectionWrapper>
      <Styled.Wrapper>
        <Styled.Description>
          <Styled.Title>{insideInfludataStrings.title}</Styled.Title>
          <Styled.SubTitle>{itemsStrings.highPerformance.title}</Styled.SubTitle>
          <Styled.Text>{itemsStrings.highPerformance.text}</Styled.Text>
        </Styled.Description>
        <Styled.InfludataImage src='../../images/HomeSlider_search.png' />
        <Styled.TryForFreeButtonWrapper>
          <TryForFreeButton />
        </Styled.TryForFreeButtonWrapper>
      </Styled.Wrapper>
    </SectionWrapper>
  )
}

export default InsideInfludata

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import queryString from 'query-string'
import { setGoogleAnalyticsQuery } from '@store/reducers/googleAnalytics'
import LandingLayout from '@layouts/LandingLayout'
import MainPerformance from './MainPerformance'
import AnalyzeUsers from './AnalyzeUsers'
import Companies from './Companies'
import Features from './Features'
import InsideInfludata from './InsideInfludata'
import Opportunities from './Opportunities'
import KeyFeatures from './KeyFeatures'
import CTA from './CTA'
import Testimonials from './Testimonials'
import * as Styled from './styled'

const MainPageContainer = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const query = window.location.search
      const parsed = queryString.parse(query)

      if (Object.keys(parsed).length) {
        dispatch(
          setGoogleAnalyticsQuery({
            utm_source: parsed.utm_source,
            utm_medium: parsed.utm_medium,
            utm_campaign: parsed.utm_campaign,
            utm_content: parsed.utm_content,
            utm_term: parsed.utm_term,
            gclid: parsed.gclid,
            li_fat_id: parsed.li_fat_id,
          }),
        )
      }
    }
  }, [])

  return (
    <LandingLayout>
      <Styled.MainPerformanceSectionGroup>
        <MainPerformance />
        <AnalyzeUsers />
      </Styled.MainPerformanceSectionGroup>
      <Companies />
      <Features />
      <InsideInfludata />
      <Opportunities />
      <KeyFeatures />
      <CTA />
      <Testimonials />
    </LandingLayout>
  )
}

export default MainPageContainer

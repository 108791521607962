import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 15px;
  display: flex;
  flex-direction: row;

  ${props => props.theme.media.lessThan('950px')`
    flex-direction: column;
  `}
`

export const DescContent = styled.div`
  ${props => props.theme.flex.centerAllColumn};
  padding: 50px 0 0 90px;

  ${props => props.theme.media.lessThan('950px')`
    width: 100%;
    margin: 25px 0 50px 0;
    padding-left: 0;
    padding-bottom: 0;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    padding-top: 0;
  `}
`

export const InfludataImage = styled.img`
  width: 750px;
  margin: 0 auto;
  display: block;

  ${props => props.theme.media.lessThan('950px')`
    width: 85%;
  `}

  ${props => props.theme.media.lessThan('400px')`
    min-width: 350px;
    width: 350px;
  `}
`

export const Title = styled.h1`
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;
  width: 90%;
  min-width: 395px;
  margin: 0 auto;
  color: ${props => props.theme.color.white};

  ${props => props.theme.media.lessThan('smallDesktop')`
    min-width: unset:
    width: auto;
  `}

  ${props => props.theme.media.lessThan('950px')`
    text-align: center;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    font-size: 34px;
    line-height: 38px;
    min-width: auto;
  `}
`

export const DescList = styled.ol`
  list-style-type: disc;
  width: 85%;
  margin-top: 45px;
  margin-bottom: 25px;

  ${props => props.theme.media.between('mobile', '950px')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  `}
`
export const DescListItem = styled.li`
  font-size: 21px;
  line-height: 24px;
  ${props => props.isBold && `font-weight: bold;`}

  & + & {
    margin-top: 10px;
  }

  ${props => props.theme.media.between('mobile', '950px')`
    width: 40%
    flex-wrap: wrap;
    margin-right: 30px;
  `}

  ${props => props.theme.media.lessThan('mobile')`
    font-size: 15px;
    line-height: 19px;
  `}
`

export const BoldText = styled.span`
  font-weight: bold;
`

export const RemarkWrapper = styled.div`
  margin-left: 10px;
  font-size: 13px;
  width: 89%;
`

import styled from 'styled-components'
import { SectionTitle } from '../styled'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px;
  border-radius: 20px;
  background-color: ${props => props.theme.color.infludataOffWhite};
`

export const Title = styled(SectionTitle)`
  margin-bottom: 5px;
  ${props => props.theme.getTextGradientStyles()}
  text-align: center;
`

export const SubTitle = styled.h4`
  font-size: 18px;
  line-height: 21px;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)}
`

export const Agreement = styled.div`
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.color.infludataBlack};
  max-width: 610px;
  margin: 0 auto;

  a {
    color: ${props => props.theme.color.infludataBlack} !important;
    padding: 0;
    display: inline;
    text-decoration: underline;
    white-space: nowrap;
    text-transform: unset;

    &:focus,
    &:active {
      color: ${props => props.theme.color.infludataBlack} !important;
      text-decoration: underline;
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-bottom: 30px;
`

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 600px;

  label {
    margin: 0 auto 5px auto !important;
  }

  input {
    border: 1px solid #d6b8b5 !important;
  }
`

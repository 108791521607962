import React, { useState, useEffect } from 'react'
import useTranslation from '@hooks/useTranslation'
import useWindowResize from '@hooks/useWindowResize'
import { TESTIMONIALS_SLIDER_RESPONSIVES } from '@constants/sliderResponsives'
import HorizontalSlider from '@common/HorizontalSlider'
import TestimonialItem from './TestimonialItem'
import { SectionWrapper } from '../styled'
import * as Styled from './styled'

const Testimonials = () => {
  const { windowWidth } = useWindowResize()
  const { labelStrings } = useTranslation()
  const { testimonials: testimonialsStrings } = labelStrings

  const [isDisplayedAsSlider, setIsDisplayedAsSlider] = useState(false)

  useEffect(() => {
    setIsDisplayedAsSlider(
      windowWidth <= TESTIMONIALS_SLIDER_RESPONSIVES.desktop.breakpoint.min,
    )
  }, [windowWidth])

  const testimonials = [
    {
      src: '../../testimonials/christian_kuper.jpg',
      name: testimonialsStrings.christianKuper.name,
      position: testimonialsStrings.christianKuper.position,
      text: testimonialsStrings.christianKuper.text,
    },
    {
      src: '../../testimonials/falke.jpg',
      name: testimonialsStrings.julia.name,
      position: testimonialsStrings.julia.position,
      text: testimonialsStrings.julia.text,
      customLogoBGStyles: `background-size: 225%; background-position: -131% 22%;`,
    },
    {
      src: '../../testimonials/sarah_emmerich.png',
      name: testimonialsStrings.sarahEmmerich.name,
      position: testimonialsStrings.sarahEmmerich.position,
      text: testimonialsStrings.sarahEmmerich.text,
    },
  ]

  const testimonialItems = testimonials.map(item => {
    return <TestimonialItem key={item.name} item={item} />
  })

  return (
    <SectionWrapper>
      <Styled.Wrapper>
        <Styled.Title>{testimonialsStrings.title}</Styled.Title>
        {isDisplayedAsSlider ? (
          <HorizontalSlider
            slidesArray={testimonialItems}
            responsivesMap={TESTIMONIALS_SLIDER_RESPONSIVES}
            isCenteredArrows
            showOnDesktop={!isDisplayedAsSlider}
            hideArrows={!isDisplayedAsSlider}
          />
        ) : (
          <Styled.TestimonialsNonSliderWrapper key='testimonials'>
            {testimonialItems}
          </Styled.TestimonialsNonSliderWrapper>
        )}
      </Styled.Wrapper>
    </SectionWrapper>
  )
}

export default Testimonials

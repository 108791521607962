import styled from 'styled-components'

export const OpportunityItems = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  ${props => props.theme.media.lessThan('tablet')`
    flex-direction: column;
  `}
`

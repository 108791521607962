import styled from 'styled-components'

export const CompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`

export const Title = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: bold;
  color: ${props => props.theme.color.infludataGray};
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
`

export const Logos = styled.div`
  max-width: 750px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0 20px;

  ${props => props.theme.media.lessThan('mobile')`
    padding: 0 15px;
  `}
`

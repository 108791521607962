import styled from 'styled-components'
import Img from 'react-image'

export const Item = styled.div`
  flex: 1;
  padding: 20px 20px 40px 20px;
  border-radius: 20px;
  background-color: ${props => props.theme.color.infludataOffWhite};

  & + & {
    margin-left: 20px;
  }

  ${props => props.theme.media.lessThan('tablet')`
      & + & {
        margin-left: 0;
        margin-top: 20px;
      }
  `}
`

export const Image = styled(Img)`
  max-width: 100%;
  max-height: 355px;
  object-fit: contain;
  margin: 0 auto;
`

export const Description = styled.div`
  padding: 0 60px;

  ${props => props.theme.media.lessThan('tablet')`
    padding: 0 20px;
  `}
`

export const Title = styled.h4`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  line-height: 1;
  min-height: 52px;
  margin: 10px 0;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)}

  ${props => props.theme.media.lessThan('tablet')`
    min-height: auto;
  `}

  ${props => props.theme.media.lessThan('tablet')`
    font-size: 22px;
  `}
`

export const Text = styled.p`
  font-size: 15px;
  line-height: 19px;
  color: ${props => props.theme.color.infludataBlack};

  ${props => props.theme.media.lessThan('tablet')`
    font-size: 14px;
  `}
`

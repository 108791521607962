import { useState, useEffect } from 'react'

const useWindowResize = () => {
  const isClient = () => typeof window === 'object'
  const [sizes, setSizes] = useState({
    windowHeight: isClient() ? window.innerHeight : undefined,
    windowWidth: isClient() ? window.innerWidth : undefined,
  })

  const onResizeHandler = () => {
    setSizes({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    })
  }

  useEffect(() => {
    if (isClient()) {
      window.addEventListener('resize', onResizeHandler)
      onResizeHandler()
    }
    return () => isClient() && window.removeEventListener('resize', onResizeHandler)
  }, [])

  return { ...sizes }
}

export default useWindowResize

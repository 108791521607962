import React from 'react'
import useTranslation from '@hooks/useTranslation'
import * as Styled from './styled'

const MainPerformance = () => {
  const { labelStrings } = useTranslation()
  const { mainPerformance } = labelStrings

  const marktextWithBold = ({ text, boldArr }) => {
    const splitted = text.split('[b]')
    return splitted.map(word =>
      boldArr.includes(word) ? (
        <Styled.BoldText key={word}>{word}</Styled.BoldText>
      ) : (
        word
      ),
    )
  }

  return (
    <Styled.Wrapper>
      <Styled.DescContent>
        <Styled.Title>{mainPerformance.title}</Styled.Title>

        <Styled.DescList>
          {mainPerformance.list.map(t => {
            const { text, bold: boldArr } = t
            const allBold = boldArr === 'all'
            const hasBold = boldArr && Array.isArray(boldArr)

            return (
              <Styled.DescListItem key={text} isBold={allBold}>
                {hasBold ? marktextWithBold({ text, boldArr }) : text}
              </Styled.DescListItem>
            )
          })}
        </Styled.DescList>

        <Styled.RemarkWrapper>{mainPerformance.remark}</Styled.RemarkWrapper>
      </Styled.DescContent>

      <Styled.InfludataImage src='../../images/infludata_search.png' />
    </Styled.Wrapper>
  )
}

export default MainPerformance

import styled from 'styled-components'

export const TestimonialItem = styled.div`
  box-shadow: 0px 0px 20px #e5e5e546;
  background-color: ${props => props.theme.color.bgLight};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  padding: 40px;
  position: relative;
  border-radius: 25px;

  & + & {
    margin-left: 30px;
  }
  ${props => props.theme.media.lessThan('1125px')`
    height: 100%;
  `}
  ${props => props.theme.media.lessThan('1250px')`
    margin: 5px 20px;
  `}
  ${props => props.theme.media.lessThan('1000px')`
    margin: 5px auto;
  `}
  ${props => props.theme.media.lessThan('smallDesktop')`
    width: 90%;
    max-width: 400px;
    margin: 0 auto;
  `}
`

export const Avatar = styled.div`
  background-image: url("${props => props.src}");
  width: 175px;
  height: 175px;
  border-radius: 50%;
  align-self: center;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: 0px 39px 42px -15px rgba(203, 179, 179, 0.44);
  
  ${props => props.customLogoBGStyles || `background-size: contain;`}

  ${props => props.theme.media.lessThan('mobile')`
    width: 130px;
    height: 130px;
  `}
`

export const Text = styled.div`
  position: relative;
  color: ${props => props.theme.color.infludataBlack};
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
`

export const PersonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
`

export const PersonName = styled.div`
  font-size: 24px;
  line-height: 26px;
  font-weight: bold;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)}
`

export const Position = styled.div`
  font-size: 13px;
  line-height: 16px;
  font-weight: normal;
  ${props => props.theme.getTextGradientStyles(props.theme.infludataBrownGradient)}
`

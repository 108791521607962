import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './styled'

const OpportunityItem = ({ title, text, imgSrc }) => {
  return (
    <Styled.Item>
      <Styled.Image src={imgSrc} />
      <Styled.Description>
        <Styled.Title>{title}</Styled.Title>
        <Styled.Text>{text}</Styled.Text>
      </Styled.Description>
    </Styled.Item>
  )
}

OpportunityItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  img: PropTypes.string,
}

OpportunityItem.defaultProps = {
  title: '',
  text: '',
  img: '',
}

export default OpportunityItem

import React, { useState } from 'react'
import queryString from 'query-string'
import { useSelector } from 'react-redux'
import { checkEmailValidation } from '@utils'
import useTranslation from '@hooks/useTranslation'
import Input from '@common/inputs/Input'
import { GradientButton } from '@common/buttons/styledButtons'
import { LocalizedLink } from '@common/links'
import { SectionWrapper } from '../styled'
import * as Styled from './styled'

const CTA = () => {
  const { labelStrings } = useTranslation()

  const [email, setEmail] = useState('')
  const [notValidEmailError, setNotValidEmailError] = useState('')

  const googleAnalyticsQuery = useSelector(state => state.googleAnalyticsQuery)

  const onEmailChange = event => setEmail(event.target.value)

  const redirectToSignUp = event => {
    event.preventDefault()
    if (checkEmailValidation(email)) {
      const redirectUrl = queryString.stringifyUrl({
        url: `${process.env.INFLUDATA_APP_HOST}/signup`,
        query: { email, ...googleAnalyticsQuery },
      })
      window.location.replace(redirectUrl)
    } else {
      setNotValidEmailError(labelStrings.reenterNotValidEmail)
    }
  }

  return (
    <SectionWrapper>
      <Styled.Wrapper>
        <Styled.Title>
          {labelStrings.tryApp} {labelStrings.forFree}
        </Styled.Title>
        <Styled.SubTitle>{labelStrings.noPayment}</Styled.SubTitle>
        <Styled.Form>
          <Styled.InputWrapper>
            <Input
              onChange={onEmailChange}
              value={email}
              labelText={labelStrings.email.toUpperCase()}
              notValidValue={!!notValidEmailError}
              noValidLabel={notValidEmailError}
            />
          </Styled.InputWrapper>
          <GradientButton onClick={redirectToSignUp}>{labelStrings.next}</GradientButton>
        </Styled.Form>
        <Styled.Agreement>
          {`${labelStrings.userAgree} `}
          <LocalizedLink page='privacy'>{labelStrings.privacyPolicy}</LocalizedLink>
          {` ${labelStrings.and} `}
          <LocalizedLink page='terms'>{labelStrings.termsOfService}</LocalizedLink>
        </Styled.Agreement>
      </Styled.Wrapper>
    </SectionWrapper>
  )
}

export default CTA

import React from 'react'
import useTranslation from '@hooks/useTranslation'
import OpportunityItem from './OpportunityItem'
import { SectionWrapper } from '../styled'
import * as Styled from './styled'

const Opportunities = () => {
  const { labelStrings } = useTranslation()
  const { metrics, order } = labelStrings.insideInfludata.items

  return (
    <SectionWrapper>
      <Styled.OpportunityItems>
        <OpportunityItem
          title={metrics.title}
          text={metrics.text}
          imgSrc="../images/HomeSlider.png"
        />
        <OpportunityItem
          title={order.title}
          text={order.text}
          imgSrc="../images/HomeSlider_statistics.png"
        />
      </Styled.OpportunityItems>
    </SectionWrapper>
  )
}

export default Opportunities

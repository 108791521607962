import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'react-image'

import useTranslation from '@hooks/useTranslation'
import { SOCIAL_PLATFORMS_INDEXES } from '@constants/socialPlatforms'
import { getLinkToUserImage } from '@utils'

import { LinkToInfludataApp } from '@common/links'
import anonProfilePic from '@static/images/anonProfilePic.jpg'


const ProfilePicWrapper = styled(Img)`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  margin-right: 15px;
  ${props => props.theme.media.lessThan('mobile')`
    height: 35px;
    width: 35px;
  `}
`

const SuggestedUsersListWrapper = styled.div`
  ${props =>
    props.isVisible
      ? `
    max-height: 328px;
    box-shadow: 0px 0px 30px #ff896833;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border-top: 1px solid ${props => props.theme.color.alto};
  `
      : `
    max-height: 0px;
`}
  position: absolute;
  background: rgba(255, 255, 255, 1);
  width: calc(100% + 2px);
  max-width: 411px;
  overflow: hidden;
  left: -1px;
  z-index: 100;
  transition: all 0.1s ease;

  .link-to-infludata-app {
    padding: 0;
    text-transform: none;
  }
`

const UserInfoWrapper = styled.div`
  width: 90%;
  color: ${props => props.theme.color.grey};
  border-bottom: 1px solid
    ${props => (props.withBorder ? props.theme.color.alto : 'transparent')};
  min-height: 55px;
  padding: 10px 0 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px auto;
  font-size: 15px;
  ${props => props.theme.media.lessThan('mobile')`
    font-size: 15px;
  `}
  ${props => props.theme.media.lessThan('400px')`
    font-size: 13px;
  `}
`

const ScrollWrapper = styled.div`
  overflow: scroll;
  overflow-x: hidden;
  max-height: 328px;
  &::-webkit-scrollbar {
    width: 3px;
    background-color: #e0e0e0;
    border-color: ${props =>
      props.index === SOCIAL_PLATFORMS_INDEXES.tiktok
        ? props.theme.color.tiktokColor
        : props.theme.color.primary};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${props =>
      props.index === SOCIAL_PLATFORMS_INDEXES.tiktok
        ? props.theme.color.tiktokColor
        : props.theme.color.primary};
  }
  scrollbar-width: thin;
`

const SuggestedUsersList = ({
  isVisible,
  suggestedUsersList,
  userSelectClickHandler,
  notFound,
  currentPlatformIndex,
}) => {
  const { labelStrings } = useTranslation()

  return (
    <SuggestedUsersListWrapper isVisible={isVisible}>
      <ScrollWrapper index={currentPlatformIndex}>
        {!!suggestedUsersList.length
          ? suggestedUsersList.map((user, idx) => {
              return (
                <LinkToInfludataApp
                  key={user._id}
                  path={`profile/${user._id}`}
                  isOpenInNewTab
                >
                  <UserInfoWrapper
                    withBorder={idx < suggestedUsersList.length - 1}
                    onClick={userSelectClickHandler}
                  >
                    <ProfilePicWrapper
                      src={getLinkToUserImage(user.profilePicURL)}
                      loader={<ProfilePicWrapper src={anonProfilePic} />}
                      unloader={<ProfilePicWrapper src={anonProfilePic} />}
                      alt='profile_logo'
                    />
                    <span>@{user.username}</span>
                  </UserInfoWrapper>
                </LinkToInfludataApp>
              )
            })
          : notFound && (
              <UserInfoWrapper>{labelStrings.noUserFound}</UserInfoWrapper>
            )}
      </ScrollWrapper>
    </SuggestedUsersListWrapper>
  )
}

SuggestedUsersList.propTypes = {
  isVisible: PropTypes.bool,
  suggestedUsersList: PropTypes.array,
  userSelectClickHandler: PropTypes.func,
  notFound: PropTypes.bool,
  currentPlatformIndex: PropTypes.string,
}

SuggestedUsersList.defaultProps = {
  isVisible: false,
  suggestedUsersList: [],
  userSelectClickHandler: () => {},
  notFound: false,
  currentPlatformIndex: '',
}

export default SuggestedUsersList

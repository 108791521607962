import React, { useState, useEffect } from 'react'
import useWindowResize from '@hooks/useWindowResize'
import HorizontalSlider from '@common/HorizontalSlider'
import useTranslation from '@hooks/useTranslation'
import { KEY_FEATURES_SLIDER_RESPONSIVES } from '@constants/sliderResponsives'
import { SectionWrapper } from '../styled'
import * as Styled from './styled'

const KeyFeatures = () => {
  const { windowWidth } = useWindowResize()
  const { labelStrings } = useTranslation()
  const { keyFeatures } = labelStrings

  const [isDisplayedAsSlider, setIsDisplayedAsSlider] = useState(false)

  useEffect(() => {
    setIsDisplayedAsSlider(
      windowWidth <= KEY_FEATURES_SLIDER_RESPONSIVES.desktop.breakpoint.min,
    )
  }, [windowWidth])

  const keyFeatureCards = keyFeatures.items.map(item => {
    const { bubbled, title } = item

    return (
      <Styled.FeatureItem key={title}>
        <Styled.FeatureBubbled>{bubbled}</Styled.FeatureBubbled>
        <Styled.FeatureTitle>{title}</Styled.FeatureTitle>
      </Styled.FeatureItem>
    )
  })

  return (
    <SectionWrapper>
      <Styled.MainWrapper>
        <Styled.Title>{keyFeatures.title}</Styled.Title>
        {isDisplayedAsSlider ? (
          <HorizontalSlider
            slidesArray={keyFeatureCards}
            responsivesMap={KEY_FEATURES_SLIDER_RESPONSIVES}
            showOnDesktop={!isDisplayedAsSlider}
            hideArrows={!isDisplayedAsSlider}
          />
        ) : (
          <Styled.FeaturesNonSliderWrapper>
            {keyFeatureCards}
          </Styled.FeaturesNonSliderWrapper>
        )}
      </Styled.MainWrapper>
    </SectionWrapper>
  )
}

export default KeyFeatures

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import useWindowResize from '@hooks/useWindowResize'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import { ChevronArrowIcon } from '@common/icons'

const SliderWrapper = styled.div`
  margin: 20px auto;
  width: ${props => (props.showOnDesktop ? 100 : 80)}%;
  max-width: 2000px;
  position: relative;

  ${props => props.theme.media.lessThan('400px')`
    width: 88%;
  `}
  & .custom_dots_group {
    top: 0;
    bottom: unset;
  }
`

const ArrowGroupWrapper = styled.div``

const ArrowCommonWrapper = styled.div`
  position: absolute;
  top: ${props => (props.isCenteredArrows ? 45 : 30)}%;
  opacity: 0.9;
  &:active {
    opacity: 1;
  }
  ${props =>
    props.isDisabled &&
    `
    pointer-events: none;
    opacity: ${props.showWhiteArrows ? 0.4 : 0.1};
  `}
`

const ToLeftArrowWrapper = styled(ArrowCommonWrapper)`
  transform: rotateZ(-90deg);
  left: -5%;
  ${props => props.theme.media.lessThan('tablet')`
    left: ${props.alwaysShowAsSlider ? '-5%' : '0px'};
  `}
`

const ToRightArrowWrapper = styled(ArrowCommonWrapper)`
  transform: rotateZ(90deg);
  right: -5%;
  ${props => props.theme.media.lessThan('tablet')`
    right:  ${props.alwaysShowAsSlider ? '-5%' : '0px'};
  `}
`

const CustomDotWrapper = styled.div`
  width: 14px;
  height: 14px;
  margin: 0 5px;
  border-radius: 50%;
  cursor: pointer;
  background: ${props =>
    props.isActive ? props.theme.color.yellow : props.theme.color.white};
`

const SliderArrowIcon = ({ showWhiteArrows }) => {
  const size = 50
  return (
    <ChevronArrowIcon
      fillColor={showWhiteArrows ? '#ffffff' : '#292929'}
      height={`${size}px`}
      width={`${size}px`}
    />
  )
}

const HorizontalSlider = ({
  slidesArray,
  responsivesMap,
  withCustomArrows,
  showWhiteArrows,
  withCustomDots,
  isCenteredArrows,
  isCarousel,
  showOnDesktop,
  hideArrows,
  alwaysShowAsSlider,
  ...props
}) => {
  const { windowWidth } = useWindowResize()
  let carouselRef = null
  useEffect(() => {
    if (
      !alwaysShowAsSlider &&
      windowWidth >= responsivesMap.desktop.breakpoint.min
    ) {
      carouselRef && carouselRef.goToSlide(0, true)
    }
  }, [windowWidth])

  const ArrowsGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide, slidesToShow, totalItems },
    } = rest
    const isDisabledLeftArrow = !currentSlide
    const isDisabledRightArrow = currentSlide + slidesToShow === totalItems
    return (
      <ArrowGroupWrapper>
        <ToLeftArrowWrapper
          onClick={previous}
          isDisabled={isDisabledLeftArrow}
          showWhiteArrows={showWhiteArrows}
          isCenteredArrows={isCenteredArrows}
          alwaysShowAsSlider={alwaysShowAsSlider}
        >
          <SliderArrowIcon showWhiteArrows={showWhiteArrows} />
        </ToLeftArrowWrapper>
        <ToRightArrowWrapper
          onClick={next}
          isDisabled={isDisabledRightArrow}
          showWhiteArrows={showWhiteArrows}
          isCenteredArrows={isCenteredArrows}
          alwaysShowAsSlider={alwaysShowAsSlider}
        >
          <SliderArrowIcon showWhiteArrows={showWhiteArrows} />
        </ToRightArrowWrapper>
      </ArrowGroupWrapper>
    )
  }

  const DotsGroup = ({ onClick, ...rest }) => {
    const { active } = rest
    return <CustomDotWrapper isActive={!!active} onClick={onClick} />
  }

  return (
    <SliderWrapper showOnDesktop={showOnDesktop}>
      <Carousel
        ref={el => (carouselRef = el)}
        responsive={responsivesMap}
        swipeable
        infinite={isCarousel}
        customTransition='all 0.3s ease'
        {...(withCustomArrows &&
          !hideArrows && {
            arrows: false,
            renderButtonGroupOutside: true,
            customButtonGroup: <ArrowsGroup />,
          })}
        {...(withCustomDots && {
          showDots: true,
          dotListClass: 'custom_dots_group',
          customDot: <DotsGroup />,
        })}
        {...(!!hideArrows && { arrows: false })}
        {...props}
      >
        {slidesArray}
      </Carousel>
    </SliderWrapper>
  )
}

HorizontalSlider.propTypes = {
  slidesArray: PropTypes.arrayOf(PropTypes.node).isRequired,
  responsivesMap: PropTypes.object.isRequired,
  withCustomArrows: PropTypes.bool,
  isCarousel: PropTypes.bool,
  showWhiteArrows: PropTypes.bool,
  withCustomDots: PropTypes.bool,
  isCenteredArrows: PropTypes.bool,
  showOnDesktop: PropTypes.bool,
  hideArrows: PropTypes.bool,
  alwaysShowAsSlider: PropTypes.bool,
}

HorizontalSlider.defaultProps = {
  withCustomArrows: true,
  isCarousel: false,
  showWhiteArrows: false,
  withCustomDots: false,
  isCenteredArrows: false,
  showOnDesktop: false,
  hideArrows: false,
  alwaysShowAsSlider: false,
}

export default HorizontalSlider

import React from 'react'
import useTranslation from '@hooks/useTranslation'
import { SectionWrapper } from '../styled'
import CompanyLogos from '@common/icons/companyLogos'
import * as Styled from './styled'

const Companies = () => {
  const { labelStrings } = useTranslation()

  return (
    <SectionWrapper>
      <Styled.CompaniesWrapper>
        <Styled.Title>{labelStrings.companiesThatTrustUs}</Styled.Title>
        <Styled.Logos>
          {CompanyLogos.map((CompanyLogo, index) => (
            <Styled.LogoWrapper key={index}>
              <CompanyLogo />
            </Styled.LogoWrapper>
          ))}
        </Styled.Logos>
      </Styled.CompaniesWrapper>
    </SectionWrapper>
  )
}

export default Companies

import React from 'react'
import PropTypes from 'prop-types'
import * as Styled from './styled'

const TestimonialItem = ({ item }) => {
  const { src, text, name, position, customLogoBGStyles } = item

  return (
    <Styled.TestimonialItem>
      <Styled.Avatar src={src} customLogoBGStyles={customLogoBGStyles} />
      <Styled.PersonWrapper>
        <Styled.PersonName>{name}</Styled.PersonName>
        <Styled.Position>{position}</Styled.Position>
      </Styled.PersonWrapper>
      <Styled.Text>{text}</Styled.Text>
    </Styled.TestimonialItem>
  )
}

TestimonialItem.propTypes = {
  item: PropTypes.shape({
    src: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.string,
    text: PropTypes.string,
    customLogoBGStyles: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(null),
    ]),
  }).isRequired,
}

TestimonialItem.defaultProps = {}

export default TestimonialItem

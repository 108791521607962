import styled from 'styled-components'

export const MainPerformanceSectionGroup = styled.div`
  background: ${props => props.theme.infludataMainGradient};
`

export const SectionWrapper = styled.div`
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  position: relative;

  ${props => props.theme.media.lessThan('tablet')`
    padding: 10px;
  `}
`

export const SectionTitle = styled.h2`
  font-size: 40px;
  line-height: 44px;
  font-weight: bold;

  ${props => props.theme.media.lessThan('mobile')`
    font-size: 32px;
    line-height: 34px;
  `}
`

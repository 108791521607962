import windowSizes from './windowSizes'

/**
 * these constants are used for sliders. sliders are created with "react-multi-carousel"
 * this is highly customizable lib, but has a bug with memory leak on resizing dynamically(the resize listener is not always removed on unmount)
 * the solution to avoid memory leaks is to use sliders always and hiding arrows for desktop and show arrows for tablet and mobile
 */

export const CARDS_SLIDER_RESPONSIVES = {
  desktop: {
    breakpoint: { max: 5000, min: 1250 },
    items: 1,
  },
  midTablet: {
    breakpoint: { max: 1250, min: 1050 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: windowSizes.mid, min: 0 },
    items: 1,
  },
}

export const KEY_FEATURES_SLIDER_RESPONSIVES = {
  desktop: {
    breakpoint: { max: 5000, min: 1150 },
    items: 4,
  },
  mid: {
    breakpoint: { max: 1150, min: windowSizes.tablet },
    items: 2,
  },
  tablet: {
    breakpoint: { max: windowSizes.tablet, min: windowSizes.mobile },
    items: 1,
  },
  mobile: {
    breakpoint: { max: windowSizes.mobile, min: 0 },
    items: 1,
  },
}

export const TESTIMONIALS_SLIDER_RESPONSIVES = {
  desktop: {
    breakpoint: { max: 5000, min: 1125 },
    items: 3,
  },
  mid: {
    breakpoint: { max: 1125, min: 1050 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 1050, min: windowSizes.mobile },
    items: 1,
  },
  mobile: {
    breakpoint: { max: windowSizes.mobile, min: 0 },
    items: 1,
  },
}

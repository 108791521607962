import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 50%;
  max-width: 527px;
  margin: 0px auto 40px auto;
  position: relative;
  ${props => props.theme.media.lessThan('tablet')`
    width: 90%;
    max-width: unset;
  `}
`

export const Title = styled.h2`
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  text-align: center;
  font-weight: 700;
  margin: 20px 0;
  color: ${props => props.theme.color.white};
`

export const AnalyzeInputWrapper = styled.div`
  position: relative;
  margin: 10px auto;
  width: 409px;
  ${props =>
    props.unroundInputBottomBorder &&
    `
    & input {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0
    }
  `}
  ${props => props.theme.media.between('mobile', 'tablet')`
    width: 80%;
    max-width: 409px;
  `}
  ${props => props.theme.media.lessThan('tablet')`
  width: 95%;
  max-width: 409px;
`}
`

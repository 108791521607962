import styled from 'styled-components'
import { SectionTitle } from '../styled'

export const Wrapper = styled.div`
  ${props => props.theme.flex.centerAllColumn}
  margin: 30px auto;
  max-width: 100%;
  width: 100%;
`

export const Title = styled(SectionTitle)`
  color: ${props => props.theme.getTextGradientStyles()};
  margin-bottom: 30px;
`

export const TestimonialsNonSliderWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`

import styled from 'styled-components'
import Img from 'react-image'
import { SectionTitle } from '../styled'

export const Wrapper = styled.div`
  min-height: 600px;
  border-radius: 20px;
  background: ${props => props.theme.infludataMainGradient};
  position: relative;

  ${props => props.theme.media.lessThan('smallDesktop')`
    min-height: 540px;
  `}

  ${props => props.theme.media.lessThan('1024px')`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

export const Description = styled.div`
  max-width: 500px;
  padding-top: 160px;
  padding-left: 100px;

  ${props => props.theme.media.lessThan('1024px')`
    max-width: unset;
    text-align: center;
    padding: 50px 50px 20px 50px;
  `}
`

export const Title = styled(SectionTitle)`
  color: ${props => props.theme.color.white};
  margin-bottom: 30px;
`

export const SubTitle = styled.h3`
  font-weight: bold;
  color: ${props => props.theme.color.white};
  font-size: 32px;
  line-height: 36px;
  margin-bottom: 5px;

  ${props => props.theme.media.lessThan('375px')`
    font-size: 18px;
    line-height: 21px;
  `}
`

export const Text = styled.p`
  font-size: 15px;
  color: ${props => props.theme.color.white};
`

export const InfludataImage = styled(Img)`
  max-width: 930px;
  position: absolute;
  top: 5%;
  right: 65px;

  ${props => props.theme.media.lessThan('1250px')`
    top: 10%;
    right: -65px;
  `}

  ${props => props.theme.media.lessThan('smallDesktop')`
    max-width: 790px;
  `}

  ${props => props.theme.media.lessThan('1024px')`
    position: static;
    top: unset;
    left: unset;
    max-width: auto;
  `}

  ${props => props.theme.media.lessThan('tablet')`
    max-width: 100%;
  `}
`

export const TryForFreeButtonWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;

  ${props => props.theme.media.between('900px', '1250px')`
    right: 5%;
  `}

  ${props => props.theme.media.lessThan('mid')`
    position: static;
    right: unset;
    margin: 20px 0;
  `}
`

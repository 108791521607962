import React from 'react'
import useTranslation from '@hooks/useTranslation'
import { CARDS_SLIDER_RESPONSIVES } from '@constants/sliderResponsives'
import HorizontalSlider from '@common/HorizontalSlider'
import { SectionWrapper } from '../styled'
import * as Styled from './styled'

const Features = () => {
  const { labelStrings } = useTranslation()
  const { features: featureStrings } = labelStrings

  const featureList = [
    {
      imageName: 'discover.jpg',
      title: featureStrings.discover.title,
      text: featureStrings.discover.text,
    },
    {
      imageName: 'check.jpg',
      title: featureStrings.check.title,
      text: featureStrings.check.text,
    },
    {
      imageName: 'activate.jpg',
      title: featureStrings.activate.title,
      text: featureStrings.activate.text,
    },
    {
      imageName: 'track.jpg',
      title: featureStrings.track.title,
      text: featureStrings.track.text,
    },
    {
      imageName: 'audience.jpg',
      title: featureStrings.audienceInsights.title,
      text: featureStrings.audienceInsights.text,
    },
    {
      imageName: 'store.jpg',
      title: featureStrings.storeAndCollect.title,
      text: featureStrings.storeAndCollect.text,
    },
  ]

  const featureCards = featureList.map((card, index) => (
    <Styled.Card key={index}>
      <Styled.CardIconWrapper>
        <Styled.CardImage src={`../images/${card.imageName}`} />
      </Styled.CardIconWrapper>
      <Styled.CardTitle>{card.title}</Styled.CardTitle>
      <Styled.CardText>{card.text}</Styled.CardText>
    </Styled.Card>
  ))

  return (
    <SectionWrapper>
      <Styled.CardsWrapper>{featureCards}</Styled.CardsWrapper>

      <Styled.SliderCardsWrapper>
        <HorizontalSlider
          slidesArray={featureCards}
          responsivesMap={CARDS_SLIDER_RESPONSIVES}
          isCenteredArrows
        />
      </Styled.SliderCardsWrapper>
    </SectionWrapper>
  )
}

export default Features
